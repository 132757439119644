<template>
    <div>
        <component :is="layout">
            <router-view />
        </component>
    </div>
    <Toast position="bottom-right"></Toast>

    <!-- error popup -->
    <Dialog
        v-model:visible="hasError"
        :style="{ maxWidth: '350px', width: '350px' }"
        :modal="true"
        class="err-dialog text-center border-round-md py-5 m-3"
        :closable="false"
    >
        <div class="confirmation-content text-center">
            <img src="@/assets/images/fail.svg" alt="success" width="140" />
            <p class="my-3 text-base font-semibold">Something Went Wrong!</p>
            <p class="mb-5">{{ message }}</p>

            <div class="m-auto" style="max-width: 230px">
                <Button
                    type="button"
                    label="Try Again"
                    class="px-4 py-3 text-sm main-bg-color text-color rounded-full border-0 w-full"
                    @click="closePopup"
                />
            </div>
        </div>
    </Dialog>
    <!-- end error popup -->

    <!-- error 2 popup -->
    <Dialog
        v-model:visible="hasError2"
        :style="{ maxWidth: '350px', width: '350px' }"
        :modal="true"
        class="err-dialog text-center border-round-md py-5 m-3"
        :closable="false"
    >
        <div class="confirmation-content text-center">
            <img src="@/assets/images/fail.svg" alt="success" width="140" />
            <p class="my-3 text-base font-semibold">Something Went Wrong!</p>
            <p class="mb-5">{{ message }}</p>

            <div class="m-auto" style="max-width: 230px">
                <Button
                    type="button"
                    label="Try Again"
                    class="px-4 py-3 text-sm main-bg-color text-color rounded-full border-0 w-full"
                    @click="closePopup"
                />
            </div>
        </div>
    </Dialog>
    <!-- end error 2 popup -->

    <!-- success popup -->
    <Dialog
        v-model:visible="hasSuccess"
        :style="{ maxWidth: '350px', width: '350px' }"
        :modal="true"
        class="err-dialog text-center border-round-md py-5 m-3"
        :closable="false"
    >
        <div class="confirmation-content text-center">
            <img src="@/assets/images/success.svg" alt="success" width="140" />
            <p class="my-3 text-base font-semibold">
                {{ title ?? "Progress Successful!" }}
            </p>
            <p class="mb-5">{{ message }}</p>

            <div class="m-auto" style="max-width: 230px">
                <Button
                    type="button"
                    label="Back To Home"
                    class="px-4 py-3 text-sm main-bg-color text-color rounded-full border-0 w-full"
                    @click="closePopup"
                />
            </div>
        </div>
    </Dialog>
    <!-- end success popup -->

    <!-- success 2 popup -->
    <Dialog
        v-model:visible="hasSuccess2"
        :style="{ maxWidth: '350px', width: '350px' }"
        :modal="true"
        class="err-dialog text-center border-round-md py-5 m-3"
        :closable="false"
    >
        <div class="confirmation-content text-center">
            <img src="@/assets/images/success.svg" alt="success" width="140" />
            <p class="my-3 text-base font-semibold">Progress Successful!</p>
            <p class="mb-5">{{ message }}</p>

            <div class="m-auto" style="max-width: 230px">
                <Button
                    type="button"
                    :label="btnLabel"
                    class="px-4 py-3 text-sm main-bg-color text-color rounded-full border-0 w-full"
                    @click="closePopup2"
                />
            </div>
        </div>
    </Dialog>
    <!-- end success 2 popup -->
</template>

<script>
import EventBus from "@/libs/AppEventBus"
import LayoutDefault from "@/layouts/default/App.vue"
import LayoutFull from "@/layouts/full/App.vue"
import LayoutCollapse from "@/layouts/collapse/App.vue"
import LayoutEmpty from "@/layouts/uiEmpty/App.vue"
import Button from "primevue/button"
import Toast from "primevue/toast"
import Dialog from "primevue/dialog"

export default {
    themeChangeListener: null,
    components: {
        LayoutDefault,
        LayoutFull,
        LayoutCollapse,
        LayoutEmpty,
        Toast,
        Button,
        Dialog
    },

    data() {
        return {
            hasError: false,
            hasError2: false,
            hasSuccess: false,
            hasSuccess2: false,
            title: "",
            message: "",
            redirect: null,
            btnLabel: "",
            routeQuery: null
        }
    },

    computed: {
        layout() {
            if (this.$route.meta.layout === "default") return "layout-default"
            if (this.$route.meta.layout === "collapse") return "layout-collapse"
            if (this.$route.meta.layout === "empty") return "layout-empty"
            return "layout-full"
        }
    },
    mounted() {
        if (this.$appState.darkTheme) {
            const elementId = "theme-link"
            const linkElement = document.getElementById(elementId)
            const cloneLinkElement = linkElement.cloneNode(true)
            const newThemeUrl = linkElement
                .getAttribute("href")
                .replace("lara-light-indigo", this.$appState.theme)

            cloneLinkElement.setAttribute("id", elementId + "-clone")
            cloneLinkElement.setAttribute("href", newThemeUrl)
            cloneLinkElement.addEventListener("load", () => {
                linkElement.remove()
                cloneLinkElement.setAttribute("id", elementId)
            })
            linkElement.parentNode.insertBefore(
                cloneLinkElement,
                linkElement.nextSibling
            )
        }
        this.themeChangeListener = (event) => {
            const elementId = "theme-link"
            const linkElement = document.getElementById(elementId)
            const cloneLinkElement = linkElement.cloneNode(true)
            const newThemeUrl = linkElement
                .getAttribute("href")
                .replace(this.$appState.theme, event.theme)

            cloneLinkElement.setAttribute("id", elementId + "-clone")
            cloneLinkElement.setAttribute("href", newThemeUrl)
            cloneLinkElement.addEventListener("load", () => {
                linkElement.remove()
                cloneLinkElement.setAttribute("id", elementId)
            })
            linkElement.parentNode.insertBefore(
                cloneLinkElement,
                linkElement.nextSibling
            )

            this.$appState.theme = event.theme
            this.$appState.darkTheme = event.dark
        }

        EventBus.on("theme-change", this.themeChangeListener)
        EventBus.on("show-toast", this.showToast)

        EventBus.on("show-success-popup", this.showSuccessPopUp)
        EventBus.on("show-success-2-popup", this.showSuccess2PopUp)
        EventBus.on("show-error-popup", this.showErrPopUp)
        EventBus.on("show-error-2-popup", this.showErr2PopUp)
    },
    methods: {
        showToast(event) {
            this.$toast.add({
                severity: event.severity,
                summary: event.summary,
                detail: event.detail,
                life: 3000
            })
        },
        showErrPopUp(event) {
            this.hasError = true
            this.message = event.detail
            console.log(event, "err")

            if (!("redirect" in event)) {
                this.redirect = null
            }
        },
        showErr2PopUp(event) {
            this.hasError2 = true
            this.message = event.detail
            this.redirect = event.redirect
            console.log(event, "err")
        },
        showSuccessPopUp(event) {
            this.hasSuccess = true
            this.message = event.detail
            this.redirect = event.redirect
        },
        showSuccess2PopUp(event) {
            this.hasSuccess2 = true
            this.message = event.detail
            this.redirect = event.redirect
            this.btnLabel = event.btnLabel
            this.routeQuery = event.routeQuery
            this.routeParams = event.routeParams
        },
        closePopup() {
            if (this.hasError) {
                this.redirect = null
            }
            this.hasSuccess = false
            this.hasError = false
            this.hasError2 = false
            this.message = ""

            if (
                this.$route.name == "saleScanner" ||
                this.$route.name == "redeemScanner" ||
                this.$route.name == "issueRewardScanner" ||
                this.$route.name == "redeemPointScanner" ||
                this.$route.name == "issuePointScanner" ||
                this.$route.name == "campaignScanner" ||
                this.$route.name == "customerScanner" ||
                this.$route.name == "testScanner"
            ) {
                EventBus.emit("close-for-try-again")
            }

            if (this.redirect !== null) {
                console.log("changing route")
                this.$router.replace({
                    name: this.redirect
                })
            }
        },
        closePopup2() {
            this.hasSuccess = false
            this.hasSuccess2 = false
            this.hasError = false
            this.hasError2 = false
            this.message = ""

            if (this.redirect !== null) {
                console.log("changing route", this.redirect)
                this.$router.replace({
                    name: this.redirect,
                    query: this.routeQuery,
                    params: this.routeParams
                })
            }
        }
    },
    beforeUnmount() {
        EventBus.off("theme-change", this.themeChangeListener)
        EventBus.off("show-toast", this.showToast)
    }
}
</script>
