const adminRoutes = [
    {
        path: "/admin",
        name: "admin",
        component: () => import("@/modules/admin/Index"),
        meta: {
            requireAuth: true,
            subject: "admin",
            action: "read",
            layout: "collapse"
        }
    },
    {
        path: "/report/list",
        name: "reportList",
        component: () => import("@/modules/admin/list/ReportList"),
        meta: {
            requireAuth: true,
            subject: "admin",
            action: "read",
            layout: "collapse"
        }
    }
]

export default adminRoutes
