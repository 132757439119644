const rankingRoutes = [
    {
        path: "/branch/ranking",
        name: "branchRanking",
        component: () => import("@/modules/branchRanking/BranchRanking.vue"),
        meta: {
            requireAuth: true,
            // subject: "earn-transhistory",
            // action: "check",
            layout: "full"
        }
    }
]

export default rankingRoutes
