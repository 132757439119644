const menuList = [
  {
    items: [
      {
        label: "dashboard",
        icon: "pi pi-fw pi-home",
        to: "/"
        // action: "read",
        // subject: "dashboard"
      },
      {
        label: "userList",
        icon: "pi pi-fw pi-user",
        to: "/user/list"
        // action: "read",
        // subject: "user"
      }
    ]
  }
]

export default menuList
