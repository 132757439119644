const rewardRoutes = [
    {
        path: "/search-rewards",
        name: "rewards",
        component: () => import("@/modules/rewards/list/Rewards"),
        meta: {
            layout: "full"
        }
    },
    {
        path: "/rewards/:id",
        name: "redwardDetail",
        component: () => import("@/modules/rewards/list/RewardDetail"),
        meta: {
            subject: "rewards",
            action: "issue",
            layout: "full"
        }
    }
]

export default rewardRoutes
