import { createRouter, createWebHistory } from "vue-router"
import dashboardRoutes from "@/modules/dashboard/route"
import userRoutes from "@/modules/user/route"
import authRoutes from "@/modules/auth/authRoute"
import { useAuthStore } from "@/modules/auth/authStore"
import transactionRoutes from "@/modules/transaction/route"
import scannerRoutes from "@/modules/scanner/route"
import profileRoutes from "@/modules/profile/route"
import adminRoutes from "@/modules/admin/route"
import { canNavigate } from "@/libs/acl/routeProtection"
import phoneRoutes from "@/modules/phone/route"
import rewardRoutes from "@/modules/rewards/route"
import campaignRoutes from "@/modules/campaigns/route"
import customerRoutes from "@/modules/customer/route"
import membershipRoutes from "@/modules/membership/route"
import gameRoutes from "@/modules/campaigns/game/route"
import rankingRoutes from "@/modules/branchRanking/route"

const routes = [
    ...dashboardRoutes,
    ...authRoutes,
    ...userRoutes,
    ...transactionRoutes,
    ...scannerRoutes,
    ...profileRoutes,
    ...adminRoutes,
    ...rewardRoutes,
    ...phoneRoutes,
    ...campaignRoutes,
    ...customerRoutes,
    ...membershipRoutes,
    ...gameRoutes,
    ...rankingRoutes,
    {
        path: "/error-404",
        name: "error404",
        component: () => import("@/pages/NotFound.vue"),
        meta: {
            layout: "full"
        }
    },
    {
        path: "/not-authorized",
        name: "not-authorized",
        component: () => import("@/pages/Access.vue"),
        meta: {
            layout: "full"
        }
    },
    {
        path: "/coming-soon",
        name: "coming-soon",
        component: () => import("@/pages/ComingSoon.vue"),
        meta: {
            layout: "full"
        }
    },
    {
        path: "/:catchAll(.*)*",
        redirect: "error-404",
        meta: {
            layout: "full"
        }
    },
    {
        path: "/maintenance",
        name: "maintenance",
        component: () => import("@/pages/Maintenance.vue"),
        meta: {
            layout: "full"
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    // Use the HTML5 history API (i.e. normal-looking routes)
    // instead of routes with hashes (e.g. example.com/#/about).
    // This may require some server configuration in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    mode: "history",
    // Simulate native-like scroll behavior when navigating to a new
    // route and using back/forward buttons.
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0, left: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)

    if (to.matched.some((record) => record.meta.requireAuth)) {
        const authStore = useAuthStore()
        const isLoggedIn = authStore.isAuth

        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: "login" })

        // If logged in => not authorized
        if (!canNavigate(to)) {
            return next({ name: "not-authorized" })
        }
        // Redirect if logged in
        // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        //     return next({ name: "dashboard" })
        // }
        return next()
    } else {
        return next()
    }
})

export default router
