import api from "@/libs/axios"

const endpoints = {
    login: "auth/merchant/login",
    forget_password: "auth/merchant/forget-password"
}

const authService = {
    login: async (params) => {
        try {
            const result = await api.post(endpoints.login, params)
            return result.data
        } catch {
            return null
        }
    },

    forgetPassword: async (params) => {
        try {
            const result = await api.post(endpoints.forget_password, params)
            return result.data
        } catch {
            return null
        }
    },

    logout: () => {}
}

export { authService }
