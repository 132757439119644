import axios from "axios"
import EventBus from "@/libs/AppEventBus"
import JSONbig from "json-bigint"
import { useAuthStore } from "@/modules/auth/authStore"
import router from "@/routers"

const api = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*"
    }
})

api.interceptors.request.use(function (config) {
    const store = useAuthStore()
    const token = store.getToken
    config.headers.common["Authorization"] = `Bearer ${token}`
    return config
})

api.interceptors.response.use(
    (res) => {
        if (res.headers["content-type"] == "application/pdf") return res

        res.data = JSONbig.parse(JSON.stringify(res.data))
        return res
    },
    (err) => {
        if (err.response) {
            if (err.response.status === 401) {
                const store = useAuthStore()
                store.logout()
                router.push({ name: "login" })
            } else if (err.response.status === 503) {
                router.push({ name: "maintenance" })
            } else if (err.response.status === 403) {
                if (
                    err.response.data &&
                    err.response.data.message !== "Too Many Attempts."
                ) {
                    router.push({ name: "not-authorized" })
                }
            }
            /**
             * Can see EventBus in AppWrapper(Top Component)
             */
            // EventBus.emit("show-toast", {
            //     severity: "error",
            //     summary: "",
            //     detail: err.message
            // })
            EventBus.emit("show-error-popup", {
                severity: "error",
                summary: "",
                detail: err.response.data.message,
                redirect: null // route name
            })
        } else if (err.request) {
            // EventBus.emit("show-toast", {
            //     severity: "error",
            //     summary: "",
            //     detail: err.message
            // })
            EventBus.emit("show-error-popup", {
                severity: "error",
                summary: "",
                detail: err.message,
                redirect: null
            })
        } else {
            // EventBus.emit("show-toast", {
            //     severity: "error",
            //     summary: "",
            //     detail: err.message
            // })
            EventBus.emit("show-error-popup", {
                severity: "error",
                summary: "",
                detail: err.message,
                redirect: null
            })
        }
    }
)

export default api
