export const initialAbility = [
    {
        action: "read",
        subject: "user"
    },
    {
        action: "edit",
        subject: "user"
    },
    {
        action: "create",
        subject: "user"
    },
    {
        action: "delete",
        subject: "user"
    },
    {
        action: "read",
        subject: "home"
    },
    {
        action: "read",
        subject: "locale"
    },
    {
        action: "read",
        subject: "admin"
    }
]

export const _ = undefined
