const transactionRoutes = [
    {
        path: "/sale/list",
        name: "saleList",
        component: () => import("@/modules/transaction/list/SaleList.vue"),
        meta: {
            requireAuth: true,
            // subject: "earn-transhistory",
            // action: "check",
            layout: "full"
        }
    },
    {
        path: "/redumption/list",
        name: "redemptionList",
        component: () =>
            import("@/modules/transaction/list/RedemptionList.vue"),
        meta: {
            requireAuth: true,
            // subject: "burn-transhistory",
            // action: "check",
            layout: "full"
        }
    },
    {
        path: "/sale/:id",
        name: "saleDetail",
        component: () => import("@/modules/transaction/list/SaleDetail.vue"),
        meta: {
            requireAuth: true,
            // subject: "earn-transhistory",
            // action: "check",
            layout: "full"
        }
    },
    {
        path: "/redumption/:id",
        name: "redemptionDetail",
        component: () =>
            import("@/modules/transaction/list/RedemptionDetail.vue"),
        meta: {
            requireAuth: true,
            subject: "burn-transhistory",
            action: "check",
            layout: "full"
        }
    },
    {
        path: "/activity/logs",
        name: "activityLogList",
        component: () => import("@/modules/transaction/list/ActivityLog.vue"),
        meta: {
            requireAuth: true,
            subject: "activitylog-transhistory",
            action: "check",
            layout: "full"
        }
    },
    {
        path: "/pay-with-coin/list",
        name: "payWithCoinList",
        component: () =>
            import("@/modules/transaction/list/PayWithCoinList.vue"),
        meta: {
            requireAuth: true,
            subject: "activitylog-transhistory",
            action: "check",
            layout: "full"
        }
    }
]

export default transactionRoutes
