<template>
    <router-link :to="{ name: 'dashboard' }">
        <img
            src="@/assets/images/pocket_merchant_logo.png"
            alt="logo"
            :width="width"
            :class="classImg"
        />
    </router-link>
</template>

<script>
export default {
    props: {
        width: {
            default: 220
        },
        classImg: {
            default: ""
        }
    }
}
</script>
