<template>
    <div
        class="header shadow-1 flex justify-content-center align-items-center px-3 py-4 bg-white"
    >
        <div class="logo">
            <Logo width="200" />
        </div>

        <!-- <AppUserProfile /> -->
    </div>
</template>

<script>
import Logo from "@/components/Logo.vue"
// import AppUserProfile from "./AppUserProfile.vue"

export default { components: { Logo } }
</script>
