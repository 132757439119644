const customerRoutes = [
    {
        path: "/customer",
        name: "customer",
        component: () => import("@/modules/customer/list/Index"),
        meta: {
            requireAuth: true,
            // subject: "customer",
            // action: "manage",
            layout: "full"
        }
    },
    {
        path: "/customer/:id",
        name: "customerDetail",
        component: () => import("@/modules/customer/list/Detail"),
        meta: {
            requireAuth: true,
            // subject: "customer",
            // action: "manage",
            layout: "full"
        }
    }
]

export default customerRoutes
