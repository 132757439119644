const campaignRoutes = [
    {
        path: "/campaigns",
        name: "campaigns",
        component: () => import("@/modules/campaigns/list/CampaignList"),
        meta: {
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/campaigns/:id",
        name: "campaignDetail",
        component: () => import("@/modules/campaigns/list/CampaignDetail"),
        meta: {
            layout: "full"
        }
    },
    {
        path: "/games/active",
        name: "activeGames",
        component: () => import("@/modules/campaigns/list/game/ActiveGameList"),
        meta: {
            subject: "games",
            action: "manage",
            layout: "full"
        }
    },
    {
        path: "/games/inactive",
        name: "inactiveGames",
        component: () =>
            import("@/modules/campaigns/list/game/InactiveGameList"),
        meta: {
            subject: "games",
            action: "manage",
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/games/:id",
        name: "gameDetail",
        component: () => import("@/modules/campaigns/list/game/GameDetail"),
        meta: {
            subject: "games",
            action: "manage",
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/quiz/active",
        name: "activeQuiz",
        component: () => import("@/modules/campaigns/list/quiz/ActiveQuizList"),
        meta: {
            subject: "quiz",
            action: "manage",
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/quiz/inactive",
        name: "inactiveQuiz",
        component: () =>
            import("@/modules/campaigns/list/quiz/InactiveQuizList"),
        meta: {
            subject: "quiz",
            action: "manage",
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/quiz/:id",
        name: "quizDetail",
        component: () => import("@/modules/campaigns/list/quiz/QuizDetail"),
        meta: {
            subject: "quiz",
            action: "manage",
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/survey/active",
        name: "activeSurvey",
        component: () =>
            import("@/modules/campaigns/list/survey/ActiveSurveyList"),
        meta: {
            subject: "surveys",
            action: "manage",
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/survey/inactive",
        name: "inactiveSurvey",
        component: () =>
            import("@/modules/campaigns/list/survey/InactiveSurveyList"),
        meta: {
            subject: "surveys",
            action: "manage",
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/survey/:id",
        name: "surveyDetail",
        component: () => import("@/modules/campaigns/list/survey/SurveyDetail"),
        meta: {
            subject: "surveys",
            action: "manage",
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/stamps/active",
        name: "activeStamps",
        component: () =>
            import("@/modules/campaigns/list/stamp/ActiveStampList"),
        meta: {
            subject: "stamps",
            action: "manage",
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/stamps/inactive",
        name: "inactiveStamps",
        component: () =>
            import("@/modules/campaigns/list/stamp/InactiveStampList"),
        meta: {
            subject: "stamps",
            action: "manage",
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/stamps/:id/issue",
        name: "issueStamp",
        component: () => import("@/modules/campaigns/list/stamp/IssueStamp"),
        meta: {
            subject: "stamps",
            action: "manage",
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/stamps/:id",
        name: "stampDetail",
        component: () => import("@/modules/campaigns/list/stamp/StampDetail"),
        meta: {
            subject: "stamps",
            action: "manage",
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/game/test",
        name: "gameTest",
        component: () => import("@/modules/campaigns/list/TestGame"),
        meta: {
            subject: "user",
            action: "create",
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/scratch/test",
        name: "scratchTest",
        component: () => import("@/modules/campaigns/list/Games/Scratch"),
        meta: {
            subject: "user",
            action: "create",
            layout: "full",
            requireAuth: true
        }
    }
]

export default campaignRoutes
