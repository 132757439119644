import { useAuthStore } from "./authStore"

const authRoutes = [
    {
        path: "/login",
        name: "login",
        component: () => import("./login/Login"),
        meta: { redirectIfLoggedIn: true, layout: "empty" },
        beforeEnter: (to, from, next) => {
            const isAuth = useAuthStore().isAuth

            if (isAuth) {
                return next({ name: "dashboard" })
            } else {
                return next()
            }
        }
    },
    {
        path: "/forget-password",
        name: "forgetPassword",
        component: () => import("./forget/Forget"),
        meta: { redirectIfLoggedIn: true, layout: "empty" }
    },
    {
        path: "/check-email/:email",
        name: "checkEmail",
        component: () => import("./forget/CheckEmail"),
        meta: { redirectIfLoggedIn: true, layout: "empty" }
    },
    {
        path: "/verify/:phone",
        name: "verify",
        component: () => import("./forget/Verify"),
        meta: { redirectIfLoggedIn: true, layout: "empty" }
    },
    {
        path: "/reset-password",
        name: "resetPassword",
        component: () => import("./forget/ResetPassword"),
        meta: { redirectIfLoggedIn: true, layout: "empty" }
    },
    {
        path: "/reset-success",
        name: "resetSuccess",
        component: () => import("./forget/ResetSuccess"),
        meta: { redirectIfLoggedIn: true, layout: "empty" }
    }
]

export default authRoutes
