const moduleRoutes = [
    {
        path: "",
        name: "dashboard",
        component: () => import("@/modules/dashboard/Index.vue"),
        meta: {
            requireAuth: true,
            layout: "collapse"
        }
    }
]

export default moduleRoutes
