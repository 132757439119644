<template>
    <div :class="containerClass" @click="onWrapperClick">
        <!-- <div class="layout-collapse-sidebar" @click="onSidebarClick">
            <AppMenuHeader
                @menu-toggle="onMenuToggle"
                :menuActive="staticMenuInactive"
                :mobileMenuActive="mobileMenuActive"
            />
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div> -->
        <!-- <AppTopBar @menu-toggle="onMenuToggle" /> -->
        <AppHeader />
        <div class="layout-collapse-main-container pt-5">
            <div class="layout-collapse-main">
                <router-view />
            </div>
        </div>
        <BottomMenu
            v-if="
                $route.name !== 'saleScanner' || $route.name !== 'redeemScanner'
            "
        />

        <transition name="layout-mask">
            <div
                v-if="mobileMenuActive"
                class="layout-mask p-component-overlay"
            />
        </transition>
    </div>
</template>

<script>
// import AppTopBar from "./AppTopbar.vue"
// import AppMenu from "./AppMenu.vue"
// import AppMenuHeader from "./AppMenuHeader.vue"
import AppHeader from "./AppHeader.vue"
import menuList from "@/menu/menu"
import BottomMenu from "./BottomMenu.vue"

export default {
    components: {
        // AppTopBar: AppTopBar,
        // AppMenu: AppMenu,
        // AppMenuHeader: AppMenuHeader,
        AppHeader,
        BottomMenu
    },
    emits: ["change-theme"],
    data() {
        return {
            layoutMode: "static",
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu: menuList,
            home: {
                icon: "pi pi-home",
                to: "/"
            }
        }
    },
    computed: {
        containerClass() {
            return [
                "layout-wrapper",
                {
                    "layout-overlay": this.layoutMode === "overlay",
                    "layout-static": this.layoutMode === "static",
                    "layout-static-sidebar-inactive":
                        this.staticMenuInactive && this.layoutMode === "static",
                    "layout-overlay-sidebar-active":
                        this.overlayMenuActive && this.layoutMode === "overlay",
                    "layout-mobile-sidebar-active": this.mobileMenuActive,
                    "p-input-filled":
                        this.$primevue.config.inputStyle === "filled",
                    "p-ripple-disabled": this.$primevue.config.ripple === false
                }
            ]
        },
        logo() {
            return this.$appState.darkTheme
                ? "images/logo-white.svg"
                : "images/logo.svg"
        }
    },
    watch: {
        $route() {
            this.menuActive = false
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, "body-overflow-hidden")
        else this.removeClass(document.body, "body-overflow-hidden")
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false
                this.mobileMenuActive = false
            }

            this.menuClick = false
        },
        onMenuToggle() {
            this.menuClick = true

            if (this.isDesktop()) {
                if (this.layoutMode === "overlay") {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true
                    }

                    this.overlayMenuActive = !this.overlayMenuActive
                    this.mobileMenuActive = false
                } else if (this.layoutMode === "static") {
                    this.staticMenuInactive = !this.staticMenuInactive
                }
            } else {
                this.mobileMenuActive = !this.mobileMenuActive
            }

            event.preventDefault()
        },
        onSidebarClick() {
            this.menuClick = true
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false
                this.mobileMenuActive = false
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode
        },
        addClass(element, className) {
            if (element.classList) element.classList.add(className)
            else element.className += " " + className
        },
        removeClass(element, className) {
            if (element.classList) element.classList.remove(className)
            else
                element.className = element.className.replace(
                    new RegExp(
                        "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
                        "gi"
                    ),
                    " "
                )
        },
        isDesktop() {
            return window.innerWidth >= 992
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === "static")
                    return !this.staticMenuInactive
                else if (this.layoutMode === "overlay")
                    return this.overlayMenuActive
            }

            return true
        }
    }
}
</script>

<style lang="scss">
@import "./App.scss";
</style>
