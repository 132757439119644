import ability from "@/libs/acl/ability"
import { defineStore } from "pinia"
import { authService } from "./authService"
// import { authService } from "./authService"

export const useAuthStore = defineStore({
    id: "useAuthStore",
    state: () => ({
        token: localStorage.getItem("userToken") || null,
        userData: JSON.parse(localStorage.getItem("userData")) || null,
        loginResponse: null,
        forgetPasswordResponse: null
    }),

    getters: {
        getToken(state) {
            return state.token
        },
        getUserData(state) {
            return state.userData
        },
        getLoginResponse(state) {
            return state.loginResponse
        },
        getForgetPasswordResponse(state) {
            return state.forgetPasswordResponse
        },
        isAuth(state) {
            return state.token ? true : false
        }
    },

    actions: {
        async login(params) {
            const response = await authService.login(params)
            this.loginResponse = response
            if (response) {
                localStorage.setItem("userToken", response.data.access_token)
                localStorage.setItem(
                    "userData",
                    JSON.stringify(response.data.merchant_user_account)
                )
                console.log(response.data.merchant_user_account)
                localStorage.setItem(
                    "userAbility",
                    JSON.stringify(
                        response.data.merchant_user_account.allowed_permissions
                    )
                )
                this.token = response.data.access_token
                this.userData = response.data.merchant_user_account

                ability.update(this.userData.allowed_permissions)
            }
        },
        updateUser(params) {
            localStorage.setItem("userData", JSON.stringify(params))
            this.userData = params
        },
        async forgetPassword(params) {
            const response = await authService.forgetPassword(params)
            this.forgetPasswordResponse = response
        },
        logout() {
            this.token = null
            this.userData = null
            localStorage.removeItem("userToken")
            localStorage.removeItem("userData")
            localStorage.removeItem("userAbility")
        }
    }
})
