<template>
    <div
        :class="[
            $route.name !== 'saleScanner' &&
            $route.name !== 'redeemScanner' &&
            $route.name !== 'issueRewardScanner' &&
            $route.name !== 'issuePointScanner' &&
            $route.name !== 'testScanner'
                ? 'pb-8'
                : ''
        ]"
    >
        <router-view />
    </div>
    <BottomMenu />
</template>

<script>
import BottomMenu from "../collapse/BottomMenu.vue"

export default { components: { BottomMenu } }
</script>

<style></style>
