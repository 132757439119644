const membershipRoutes = [
    {
        path: "/memberships/:id",
        name: "memberships",
        component: () => import("@/modules/membership/list/list.vue"),
        meta: {
            requireAuth: true,
            layout: "full",
            action: "manage",
            subject: "membership"
        }
    },
    {
        path: "/memberships/:id/new",
        name: "membershipNew",
        component: () => import("@/modules/membership/entry/New.vue"),
        meta: {
            requireAuth: true,
            layout: "full",
            action: "manage",
            subject: "membership"
        }
    }
]

export default membershipRoutes
