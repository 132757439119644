const phoneRoutes = [
    {
        path: "/search-phone/:type",
        name: "searchByPhone",
        component: () => import("@/modules/phone/entry/SearchByPhone"),
        meta: {
            layout: "full"
        }
    }
]

export default phoneRoutes
