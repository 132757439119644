<template>
    <div class="pb-8">
        <router-view />
    </div>
</template>

<script>
export default {}
</script>

<style></style>
