const gameRoutes = [
    // with default game id
    {
        path: "/games",
        name: "games",
        component: () => import("@/modules/campaigns/game/detail.vue"),
        meta: {
            subject: "games",
            action: "manage",
            layout: "full"
        }
    }
]

export default gameRoutes
