const profileRoutes = [
    {
        path: "/profile/detail",
        name: "ProfileDetail",
        component: () => import("@/modules/profile/entry/Detail.vue"),
        meta: {
            requireAuth: true,
            layout: "full"
        }
    },
    {
        path: "/profile/edit",
        name: "ProfileEdit",
        component: () => import("@/modules/profile/entry/ProfileEdit.vue"),
        meta: {
            requireAuth: true,
            layout: "full"
        }
    },
    {
        path: "/password/edit",
        name: "ChangePassword",
        component: () => import("@/modules/profile/entry/ChangePassword.vue"),
        meta: {
            requireAuth: true,
            layout: "full"
        }
    }
]

export default profileRoutes
